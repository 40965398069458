<template>
    <div>
        <div class="r-option-place r-center-flex r-isWrap">
            <div class="r-center-flex y-md-size">
                {{item.kode_pasaran}} - {{item.keterangan}}
            </div>
        </div>

        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        >
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <template #updated_at="props">
                {{convertDateTime(props.row.updated_at)}}
            </template>

            <template #updated_by="props">
                {{props.row.updated_by}}
            </template>

            <template #hari="props">
                {{props.row.hari}}
            </template>
            
            <template #status="props">
                <ButtonCustom
                    :type="pasaranOnlineStatus(props.row.status).type"
                    :disabled="statusLoading != null"
                    @click.stop="updateStatus(props.row)"
                >
                    <img :src="require('@/assets/loadingDot.svg')" alt style="width: 50px;" v-if="statusLoading == props.row.id" />
                    <span v-else>
                        {{pasaranOnlineStatus(props.row.status).label}}
                    </span>
                </ButtonCustom>
            </template>
        </TableCustom>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'pasaranOnline/getData',
            request: 'pasaranOnline/getRequest',
            pasaranOnlineStatus: 'pasaranOnline/getPasaranOnlineStatus',
            item: 'pasaran/getItem',
        }),

    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            {
                label: 'Diupdate Pada',
                isSortable: true,
                fieldName: 'updated_at',
                class: ''
            },
            {
                label: 'Diupdate Oleh',
                isSortable: true,
                fieldName: 'updated_by',
                class: ''
            },
            {
                label: 'Hari',
                isSortable: true,
                fieldName: 'hari',
                class: ''
            },
            {
                label: 'Status',
                isSortable: false,
                fieldName: 'status',
                class: ''
            }
        ],
        inputType: 'Tambah',
        statusLoading: null
    }),
    methods: {
        ...mapActions ({
            resetItem: 'pasaranOnline/resetItem',
            resetRequest: 'pasaranOnline/resetRequest',
            setItem: 'pasaranOnline/setItem',
            getPasarans: 'pasaranOnline/fetchData',
            updateStatusPasaranOnline: 'pasaranOnline/updateStatus',
        }),
        resetData() {
            this.statusLoading = null
            this.resetItem()
            this.resetRequest()
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getPasarans(this.$route.params.id)
            this.fetchData = false
            const response = this.getResponse('pasaranOnline')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async initData() {
            const breadcrumb = [
                {
                    name: 'Pasaran',
                    link: {name: 'ViewSettingPasaran'},
                    current: false
                },
                {
                    name: 'Hari Pasaran',
                    link: {name: null},
                    current: true
                },

            ]
            this.setActiveTab('Pasaran')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
        },
        async updateStatus(item) {
            this.statusLoading = item.id
            this.loading = true
            await this.updateStatusPasaranOnline(item)
            this.loading = false
            const response = this.getResponse('pasaranOnline')
            this.showSnackbar({
                type: response.status == 1 ? 'success': 'error',
                text: response.msg
            })
            this.resetData()
            this.getData()
        }
    }
}
</script>